import React from 'react';
import { Layout, Row, Col, Carousel, Card, Button, Typography, Flex } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const { Title, Paragraph } = Typography;

function App() {
  return (
    <Layout className="layout">
      <div className="hero-video">
        <video autoPlay loop muted playsInline>
          <source src={`${process.env.PUBLIC_URL}/video.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay"></div> {/* Dark overlay */}
        <div className="video-text">
          <div className="app-logo">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="App Logo" style={{ maxWidth: '150px' }} />
          </div>
          <h1>Join the Future of Ecommerce</h1>
          <p>Connect with a wider audience and boost customer loyalty by joining our innovative points-sharing network. Elevate your store’s value and drive repeat business effortlessly.</p>
          <Button size="large" type="primary" href="https://apps.shopify.com/ewallet">Let’s grow together!</Button>
        </div>
      </div>

      <Content className="content-padding">
        <div className="site-layout-content">
          <Card title={<Title level={4}>Expand Your Reach with Shared Rewards</Title>}>
            <div className="flex-container">
              <Paragraph style={{ fontSize: '20px' }}>
                Discover our loyalty points app, a transformative solution connecting stores in a shared rewards network. This innovative system incentivizes customers to explore and spend across multiple businesses, boosting store visibility and customer engagement. Elevate your store’s profile and unlock new revenue by making every purchase rewarding.
              </Paragraph>
              <Paragraph style={{ fontSize: '20px' }}>
                Empower your store with our app’s flexible exchange system, allowing you to set your own rates for converting store points into Mint3D points. These can then be seamlessly exchanged for points at other participating stores, offering unparalleled versatility and value to your customers.
              </Paragraph>
            </div>
          </Card>


          <Row gutter={[32, 16]} style={{ marginTop: '20px' }}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                hoverable
                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/1.png`} />}
              >
                <Meta title={<Title level={2}>Boosted Spending Incentives</Title>} description={<Paragraph style={{ fontSize: '18px' }}>Stimulate increased spending within your store by offering more versatile and attractive rewards. Our innovative exchange system encourages customers to earn and use points not just within your store, but across an entire network, creating a compelling reason for more frequent visits and higher spending.</Paragraph>} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                hoverable
                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/2.png`} />}
              >
                <Meta title={<Title level={2}>Enhanced Customer Value</Title>} description={<Paragraph style={{ fontSize: '18px' }}>Unlock unmatched value for your customers by joining our loyalty network. Our app enables shoppers to leverage every purchase into rewards that go further, transforming their spending into a gateway for benefits across a multitude of stores. This not only elevates their shopping experience but also solidifies their loyalty to your brand.</Paragraph>} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card
                hoverable
                cover={<img alt="example" src={`${process.env.PUBLIC_URL}/3.png`} />}
              >
                <Meta title={<Title level={2}>Maximizing Unused Loyalty Potential</Title>} description={<Paragraph style={{ fontSize: '18px' }}>Tap into the vast, untapped potential of dormant loyalty points with our app. By facilitating the circulation of these unused rewards, we help you attract new customers and re-engage existing ones, turning overlooked assets into valuable opportunities for growth and engagement for your business.</Paragraph>} />
              </Card>
            </Col>
          </Row>


          {/* <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card
                hoverable
                cover={<img alt="Your GIF" src={`${process.env.PUBLIC_URL}/short.gif`} />}
              >
                <Meta title="GIF Section" description="This is a section with your GIF." />
              </Card>
            </Col>
          </Row> */}

          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card>
                <Flex align='center' justify='center'>
                  <Button size="large" type="primary" href="https://apps.shopify.com/ewallet">Let’s grow together!</Button>
                </Flex>
              </Card>
            </Col>
          </Row>



          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card>
                <Carousel autoplay>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-2.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-3.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-4.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-5.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-6.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-7.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/Mint3D-8.png`} alt="Carousel 1" style={{ width: '100%' }} />
                  </div>
                </Carousel>
              </Card>
            </Col>
          </Row>

        </div>

        <div className="site-layout-content">
          {/* Other content */}

          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card>
                <Flex align='center' vertical>
                  <Paragraph>If you have any questions or need support, don't hesitate to reach out:</Paragraph>
                  <Button type="primary" href="mailto:support@mint3d.com">Email Support</Button>
                </Flex>
              </Card>
            </Col>
          </Row>

        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Mint3D ©2024
      </Footer>
    </Layout >
  );
}

export default App;
